import React from 'react';
import Bgimg from '../../assets/images/shapes/service-bg-1.jpg';
import fireman from '../../assets/images/reconImages/home/fireman.jpg';
import training from '../../assets/images/reconImages/home/training.jpg';
import healing from '../../assets/images/reconImages/home/healing.jpg';

const services = [
    {
        image: training,
        icon: "icon-consulting",
        category: "Management",
        title: "อบรมหลักสูตรรักษาความปลอดภัยภาคปฏิบัติ และ ภาคทฤษฎี",
        link: "/service-consulting",
        text: "จัดฝึกอบรมการอบรมหลักสูตรรักษาความปลอดภัยภาคปฏิบัติ และ ภาคทฤษฎีเพื่อการเป็นเจ้าหน้ารักษาความปลอดภัยที่ครบพร้อมและพร้อมรับมือทุกสถานการณ์ในทุกด้าน"
    },
    {
        image: healing,
        icon: "icon-controller",
        category: "Resources",
        title: "อบรบเกี่ยวกับการปฐมพยาบาลเบื้องต้น",
        link: "/service-resources",
        text: "เป็นการจัดอบรมการปฐมพยาบาลเบื้องต้นเพื่อนำไปใช้เมื่อเกิดเหตุการอบุติเหตุ ก็จะสามารถรับมือแก้ไขสถานการณ์เบื้องต้น"
    },
    {
        image: fireman,
        icon: "icon-consulting",
        category: "Management",
        title: "จัดฝึกอบรมการดับเพลิงขั้นต้น",
        link: "/service-consulting",
        text: "จัดฝึกอบรมการดับเพลิงขั้นต้นเพื่อให้ความรู้แก่พนักงานทั้งด้านการป้องกันและแนวทางในการปฏิบัติเมื่อเกิดอัคคีภัยขึ้นในพื้นที่ที่รับผิดชอบเพื่อเพิ่มทักษะความรู้ความสามารถในการใช้อุปกรณ์ดับเพลิงเข้าทำการระงับเหตุเบื้องต้นได้อย่างรวดเร็วและมีประสิทธิภาพ ช่วยลดความสูญเสียทั้งชีวิตและทรัพย์สิน"
    },
];

function Service() {
    return (
        <div>
            <section className="service-one" style={{ backgroundImage: `url(${Bgimg})` }}>
                <div className="container">                  <div className="row gutter-y-30">
                    {services.map((service, index) => (
                        <div key={index} className="col-md-6 col-lg-4">
                            <div className="service-one-card service-card-two h-100 pb-3" style={{ maxHeight: 800 }} data-wow-duration="1500ms" data-wow-delay={`${index}ms`}>
                                <div className="service-one-card__image">
                                    <img src={service.image} alt={service.title} />
                                </div>
                                <div className="service-one-card__content" style={{ maxHeight: 400, minHeight: 400 }}>
                                    <div className="service-one-card__icon">
                                    </div>
                                    <h3 className="service-one-card__title">
                                        <div>{service.title}</div>
                                    </h3>
                                    <p className="service-one-card__text">
                                        {service.text}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                </div>
            </section>
        </div>
    );
}

export default Service;
