import React from 'react'
import { Link } from 'react-router-dom'


function Main({ subtitle, title, detail, image }) {
    return (
        <>
            <section className="page-header">
                <div className="page-header__bg" />
                <div className="page-header__shape">
                    <img src={image} alt="banner-image" />
                </div>
                <div className="container">
                    <h2 className="page-header__title">{title}</h2>
                    {detail ? <ul className="hiredots-breadcrumb list-unstyled">
                        <li>
                            <Link to="/">{subtitle}</Link>
                        </li>
                        <li>
                            <span>{title}</span>
                        </li>
                    </ul> : <></>}
                </div>
            </section>
        </>
    )
}

export default Main