import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main';
import { Link } from 'react-router-dom';

const employeeStatus = [
  { title: 'อายุ 18 -55 ปี', no: 1 },
  { title: 'สูง หญิง 155 และ ชาย 165 ซ.ม.ขึ้นไป', no: 2 },
  { title: 'ชื้อชาติไทย  สัญชาติไทย', no: 3 },
  { title: 'ผ่าน พ.ร.บ.ธุรกิจรักษาความปลอดภัยรับอนุญาต', no: 4 },
  { title: 'บุคลิกดูดีมีสง่า', no: 5 },
  { title: 'ผ่านการตรวจประวัติจาก Data  base security of Thailand และไม่มีลักษณะต้องห้ามตาม มาตรา 34 วรรคสาม ข. พ.ร.บ.ธุรกิจรักษาความปลอดภัย พ.ศ.2558 ได้แก่', no: 6 },
  { title: 'เป็นโรคพิษสุราเรื้อรัง  หรือติดยาเสพติด หรือโรคติดต่อฯ', no: 6.1 },
  { title: 'วิกลจริตหรือจิตฟั่นเฟือน', no: 6.2 },
  { title: 'ถูกศาลพิพากษาจำคุก (ความผิดเกี่ยวกับชีวิตร่างกาย, ทรัพย์, เพศ, และการพนัน เป็นต้น)', no: 6.3 },
];

const employeeStatusEng = [
  { title: 'Age 18 -55 years', no: 1 },
  { title: 'Height for women 155 and men 165 cm. and up.', no: 2 },
  { title: 'Thai nationality, Thai nationality', no: 3 },
  { title: 'Passed the Licensed Security Business Act', no: 4 },
  { title: 'Good-looking and dignified personality', no: 5 },
  { title: 'Passed a background check from the Data base security of Thailand and do not have prohibited characteristics according to Section 34, paragraph three, B. Security Business Act 2015, including :', no: 6 },
  { title: 'is alcoholism  or addicted to drugs or contagious diseases', no: 6.1 },
  { title: 'insanity or mental insanity', no: 6.2 },
  { title: 'Was sentenced by the court to imprisonment (Offenses related to physical life, property, sex, and gambling, etc.)', no: 6.3 },
];

export default function Employee() {
  return <>
    <Header />
    <div className='mx-5'>
      <h3 className="project-details__highligt w-100" style={{ color: '#0b2f81' }}>
        คุณสมบัติพนักงาน
      </h3>
      <ul>
        {employeeStatus.map((data, index) => (
          <li key={index}>
            {data.no}. {data.title}
          </li>
        ))}
      </ul>
      <h3 className="project-details__highligt w-100" style={{ color: '#0b2f81' }}>
        Employee qualifications
      </h3>
      <ul>
        {employeeStatusEng.map((data, index) => (
          <li key={index}>
            {data.no}. {data.title}
          </li>
        ))}
      </ul>
      <div className='my-5'>
        <Link to="tel:+6693-6434525" className="hiredots-btn">
          <span>โทร: 093-6434525</span>
        </Link>
      </div>
    </div>
    <Footer />
  </>
}