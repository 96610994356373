import { Link } from 'react-router-dom';
import Icon from '../assets/images/reconImages/logo.png';

export default function NotFound() {
  return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <img src={Icon} alt='icon' height={200} />
    <h4 className='my-5' style={{ color: '#0b2f81' }}>ไม่พบหน้านี้</h4>
    <Link to="/" className="hiredots-btn">
      <span>กลับหน้าแรก</span>
    </Link>
  </div>
}