import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main';
import Details from '../components/Menteamdetails/Details';

export default function Vision() {
  return <>
    <Header />
    <Details />
    <Footer />
  </>
}