import React from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import Bgimg from '../../assets/images/reconImages/banner1.jpg'
import Bgimg2 from '../../assets/images/reconImages/banner2.jpg'
import Bgimg3 from '../../assets/images/reconImages/banner3.jpg'

const HomesliderTwo = {
    loop: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 6000,
    smartSpeed: 1000,
    margin: 0,
}

function Sliderone() {
    return (
        <div>
            <section className="main-slider-one">
                <Slider className='' {...HomesliderTwo} slidesToShow={1} slidesToScroll={1} arrows={false}>
                    <div className="item" key={1}>
                        <div className="main-slider-one__item">
                            <div className="main-slider-two__bg" style={{ backgroundImage: `url(${Bgimg})` }} />
                            <div className="main-slider-one__overlay" />
                            {/* <div className="main-slider-one__shape" style={{ backgroundImage: `url(${Bgimg1})` }} /> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="main-slider-one__content">
                                            <div className="main-slider-one__image">

                                            </div>
                                            <h2 className="main-slider-one__title">
                                                บริษัท รักษาความปลอดภัย
                                                <br /> รีคอน การ์ด จำกัด
                                                <br /> &nbsp;
                                            </h2>
                                            <div className="main-slider-one__btn pb-5">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item" key={2} data-dot="<button>2/</button>">
                        <div className="main-slider-one__item">
                            <div className="main-slider-one__bg" style={{ backgroundImage: `url(${Bgimg2})` }} />
                            <div className="main-slider-one__overlay" />
                            {/* <div className="main-slider-one__shape" style={{ backgroundImage: `url(${Bgimg1})` }} /> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="main-slider-one__content">
                                            <div className="main-slider-one__image">

                                            </div>
                                            <h2 className="main-slider-one__title">
                                                พิทักษ์และดูแลผลประโยชน์
                                                <br /> ให้ผู้ว่าจ้างอย่าง
                                                <br /> <span className='text-danger'>มีมาตรฐาน</span>
                                            </h2>
                                            <div className="main-slider-one__btn pb-5">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item" key={3} data-dot="<button>3</button>">
                        <div className="main-slider-one__item">
                            <div className="main-slider-one__bg" style={{ backgroundImage: `url(${Bgimg3})` }} />
                            <div className="main-slider-one__overlay" />
                            {/* <div className="main-slider-one__shape" style={{ backgroundImage: `url(${Bgimg1})` }} /> */}
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="main-slider-one__content">
                                            <div className="main-slider-one__image">

                                            </div>
                                            <h2 className="main-slider-one__title">
                                                ความปลอดภัยในชีวิตและ
                                                <br /> ทรัพย์สินของท่านคือ
                                                <br />  <span className='text-danger'>ภารกิจของเรา</span>
                                            </h2>
                                            <div className="main-slider-one__btn">
                                                <Link to="/" className="hiredots-btn">
                                                    <span>ติดต่อเรา</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>
        </div>
    )
}

export default Sliderone