import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/images/reconImages/logo.png'
import Logo1 from '../../assets/images/logo-light.png'
import WOW from 'wowjs'

function Main() {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [homeDrop, sethomeDrop] = useState(false)
    const [headerDrop, setheaderDrop] = useState(false)
    const [pageDrop, setpageDrop] = useState(false)
    const [servicesDrop, setservicesDrop] = useState(false)
    const [projectsDrop, setprojectsDrop] = useState(false)
    const [blogDrop, setblogDrop] = useState(false)
    const [search, setsearch] = useState(false)
    const location = useLocation()
    const path = location.pathname
    const [menu, setmenu] = useState({})
    const activeMenu = () => {
        if (path === "/" || path === "/") {
            setmenu({ home: true })
        } else if (path === "/header" || path === "/header") {
            setmenu({ header: true })
        } else if (path === "/pages" || path === "/pages") {
            setmenu({ pages: true })
        } else if (path === "/projects" || path === "/projects") {
            setmenu({ projects: true })
        } else if (path === "/services" || path === "/services") {
            setmenu({ services: true })
        } else if (path === "/blog" || path === "/blog") {
            setmenu({ blog: true })


        }
    }


    const [sticky, setSticky] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        new WOW.WOW({
            live: false
        }).init();
        activeMenu()
    }, [path]);
    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const closeMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <>
            <div className="topbar-one">
                <div className="container-fluid">
                    <div className="topbar-one__inner">
                        <ul className="list-unstyled topbar-one__info">
                            <li>
                                <span><i className="fas fa-map-marker mx-1" style={{ color: '#00cde5' }} />39/25 หมู่ที่ 10 ต.บางเสร่ อ.สัตหีบ จ.ชลบุรี</span>
                            </li>
                        </ul>
                        <div className="topbar-one__right">
                            <div className="topbar-one__nav">
                                <ul>
                                    <li>
                                        <Link to="/about-us">เกี่ยวกับเรา</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us">ติดต่อเรา</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="topbar-one__social">
                                <Link to="https://facebook.com">
                                    <i className="fab fa-facebook" aria-hidden="true" />
                                    <span className="sr-only">Facebook</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className={`main-header sticky-header sticky-header--normal ${sticky && "main-header sticky-header sticky-header--cloned active"}`}>
                <div className="main-header__inner">
                    <div className="main-header__logo">
                        <Link to="/">
                            <img src={Logo} alt="reconguard" width={120} />
                        </Link>
                    </div>
                    <div className="main-header__menu">
                        <nav className="main-header__nav main-menu">
                            <ul className="main-menu__list">
                                <li className={`dropdown ${menu.home && "current"}`}>
                                    <Link to="/">หน้าแรก</Link>
                                </li>
                                <li>
                                    <Link to="/vision">วิสัยทัศน์/พันธกิจ</Link>
                                </li>
                                <li>
                                    <Link to="/">ผลงานและการบริการ</Link>
                                </li>
                                <li className={`dropdown ${menu.pages && "current"}`}>
                                    <Link to="#">สถานฝึกอบรมหลักสูตรการรักษาความปลอดภัย</Link>
                                    <ul>
                                        <li>
                                            <Link to="/education">หลักสูตรการรักษาความปลอดภัย</Link>
                                        </li>
                                        <li>
                                            <Link to="/employee">คุณสมบัติพนักงาน</Link>
                                        </li>
                                        <li>
                                            <Link to="/benefit">สวัสดิการพนักงาน</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/">ข่าวสาร และกิจกรรม</Link>
                                </li>
                                <li>
                                    <Link to="/about-us">เกี่ยวกับเรา</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">ติดต่อเรา</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className="main-header__call">
                            <i className="icon-telephone" />
                            <Link to="tel:+6693-6434525">093-6434525</Link>
                        </div>
                    </div>
                    <div className="mobile-nav__btn mobile-nav__toggler" onClick={toggleMobileMenu}>
                        <span />
                        <span />
                        <span />
                    </div>
                </div>
            </header>

            {search &&
                <div className="search-popup active">
                    <div className="search-popup__overlay search-toggler" onClick={() => setsearch(false)} />
                    <div className="search-popup__content">
                        <form role="search" method="get" className="search-popup__form" action="#">
                            <input type="text" id="search" placeholder="Search Here..." />
                            <button type="submit"
                                aria-label="search submit" className="hiredots-btn hiredots-btn--base">
                                <span>
                                    <i className="icon-magnifying-glass" />
                                </span>
                            </button>
                        </form>
                    </div>
                </div>
            }

            {mobileMenuOpen &&
                <div className={`mobile-nav__wrapper ${mobileMenuOpen ? "expanded" : ""}`}>
                    <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setIsDropdownOpen(false)} />
                    <div className="mobile-nav__content">
                        <span className="mobile-nav__close mobile-nav__toggler" onClick={() => setMobileMenuOpen(false)}>
                            <i className="fa fa-times" />
                        </span>
                        <div className="logo-box">
                            <Link to="/" aria-label="logo image" onClick={() => setIsDropdownOpen(false)}>
                                {/* <img src={Logo1} width={155} alt="" /> */}
                            </Link>
                        </div>
                        <div className="mobile-nav__container">
                            <ul className="main-menu__list">
                                <li className="dropdown current">
                                    <Link to="/" className={homeDrop ? "expanded" : ""}>หน้าแรก</Link>
                                </li>
                                <li>
                                    <Link to="/vision">วิสัยทัศน์/พันธกิจ</Link>
                                </li>
                                <li>
                                    <Link to="/">ผลงานและการบริการ</Link>
                                </li>
                                <li className="dropdown current">
                                    <Link to="#" className={pageDrop ? "expanded" : ""}>สถานฝึกอบรมหลักสูตรการรักษาความปลอดภัย<button aria-label="dropdown toggler" className={pageDrop ? "expanded" : ""} onClick={() => setpageDrop(pageDrop ? false : true)} ><i className="fa fa-angle-down"></i></button></Link>
                                    {pageDrop &&
                                        <ul style={{ display: "block" }}>
                                            <li>
                                                <Link to="/education">หลักสูตรการรักษาความปลอดภัย</Link>
                                            </li>
                                            <li>
                                                <Link to="/employee">คุณสมบัติพนักงาน</Link>
                                            </li>
                                            <li>
                                                <Link to="/benefit">สวัสดิการพนักงาน</Link>
                                            </li>
                                        </ul>}
                                </li>
                                <li>
                                    <Link to="/">ข่าวสาร และกิจกรรม</Link>
                                </li>
                                <li>
                                    <Link to="/about-us">เกี่ยวกับเรา</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">ติดต่อเรา</Link>
                                </li>
                            </ul>
                        </div>
                        <ul className="mobile-nav__contact list-unstyled">
                            <li>
                                <i className="fa fa-phone-alt" />
                                <Link to="tel:+6693-6434525">093-6434525</Link>
                            </li>
                        </ul>
                        <div className="mobile-nav__social">
                            <Link to="https://facebook.com">
                                <i className="fab fa-facebook" aria-hidden="true" />
                                <span className="sr-only">Facebook</span>
                            </Link>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

export default Main