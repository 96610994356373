import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main';
import { Link } from 'react-router-dom';

const employeeStatus = [
  { title: 'ค่าแรง 545-600./วัน', no: 1 },
  { title: 'ค่าตำแหน่ง', no: 2 },
  { title: 'เบี้ยขยัน 500-1000./เดือน', no: 3 },
  { title: 'ค่าวันหยุดนักขัตฤกษ์ ปีละ 13 วัน', no: 4 },
  { title: 'ประกันสังคม', no: 5 },
  { title: 'ประกันชีวิตกลุ่ม', no: 6 },
  { title: 'กองทุนฌาปนกิจ', no: 7 },
  { title: 'ทุนการศึกษาบุตร', no: 8 },
  { title: 'บ้านพักพนักงาน', no: 9 },
  { title: 'มีรถรับส่งจาก บ้านพักถึงจุดปฏิบัติงาน', no: 10 },
  { title: 'เงินเบิกรายวัน ผ่าน บช.ธนาคาร', no: 11 },
  { title: 'เงินเดือนออกเดือนละ 1 ครั้ง/เดือน', no: 12 },
];

export default function Benefit() {
  return <>
    <Header />
    <div className='mx-5'>
      <h3 className="project-details__highligt w-100" style={{ color: '#0b2f81' }}>
        สวัสดิการพนักงาน
      </h3>
      <ul>
        {employeeStatus.map((data, index) => (
          <li key={index}>
            {data.no}. {data.title}
          </li>
        ))}
      </ul>
      <div className='my-5'>
        <Link to="tel:+6693-6434525" className="hiredots-btn">
          <span>โทร: 093-6434525</span>
        </Link>
      </div>
    </div>
    <Footer />
  </>
}