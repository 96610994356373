import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main';
import AboutUsImage from '../assets/images/reconImages/aboutUs/aboutUs.jpg';
import ChooseShape from '../assets/images/shapes/about-circle-shape.png';

export default function AboutUs() {
  return <>
    <Header />
    <div>
      <section className="why-choose-two">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="why-choose-two__image">
                <img src={AboutUsImage} alt="title" />
                <img src={ChooseShape} className="why-choose-two__image__shape" alt="anime" />
                <div className="why-choose-two__circle">
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="why-choose-two__content">
                <div className="sec-title text-left">
                  <h4 style={{ color: '#0b2f81' }}>เกี่ยวกับองค์กรของเรา</h4>
                  <h3 className="sec-title__title mt-3">
                    บริษัทรักษาความปลอดภัย <br />รีคอน การ์ด จำกัด
                  </h3>
                </div>
                <p className="why-choose-two__text">
                  บริษัทรักษาความปลอดภัย รีคอน การ์ด จำกัด  SECURITY RECON GUARD.CO.,LTD. เป็นบริษัทด้านการให้บริการเริ่มก่อตั้ง <br />
                  เมื่อวันที่ 4 กุมภาพันธ์ 2557  ด้วยทุนจดทะเบียน 5,000,000 (ห้าล้านบาทถ้วน) มีสำนักงานใหญ่อยู่ที่  เลขที่  39/25  หมู่ที่  10  ถนนสุขุมวิท  ตำบลบางเสร่ <br />
                  อำเภอสัตหีบ  จังหวัดชลบุรี  20250   ได้จัดตั้งขึ้นจากการรวมพลังความคิด และประสบการณ์จากคณะทำงานทั้งภาคราชการ และพลเรือนที่ชำนาญการอย่างสูงด้านการรักษาความปลอดภัย และความปลอดภัย <br />
                  (Security & Safety measures) และได้ปฏิบัติตามนโยบายของรัฐบาล<br />
                  ภายใต้ พรบ.รักษาความปลอดภัย 2558 (Security Business Acts) <br />
                  เพื่อมุ่งมั่นดำเนินธุรกิจรักษาความปลอดภัย ให้เกิดประสิทธิภาพสูงสุดแก่ <br />
                  หน่วยงานราชการ รัฐวิสาหกิจและเอกชนโดยกลุ่มผู้บริหารที่มีความรู้ <br />
                  ความสามารถหลากหลายวิชาชีพ อาทิ นักบริหาร ทนายความ นักบัญชี <br />
                  ผู้สอบบัญชี นักบริหารสินเชื่อ นักพัฒนาบุคลากร ผู้ชำนาญการวิชาชีพรักษาความปลอดภัย, ด้านงานแม่บ้านรักษาความสะอาด, งานคนสวน, <br />
                  งานก่อสร้าง และนักวิชาการ จำนวนมาก เพื่อขยายกลุ่มธุรกิจตามนโยบายของคณะกรรมการและผู้ถือหุ้น
                  ภายใต้แนวคิด PROFESSIONAL FOR BUSINESS  และกำหนดหัวใจในการให้บริการกับลูกค้าว่า   “STRIVE / KNOW DUTY / DISCIPLINE / ATTENTIVE SERVICE   มุ่งมั่น  รู้หน้าที่    มีวินัย   ใส่ใจบริการ
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </>
}