import React from 'react'
import Header from '../components/Header/Main'
import EducationBanner from '../assets/images/reconImages/education/education.jpg'
import Footer from '../components/Footer/Main'

const educationList = [
  { title: 'ความรู้เบื้องต้นเกี่ยวกับธุรกิจรักษาความปลอดภัย', hours: 2 },
  { title: 'กฎหมายที่เกี่ยวข้องกับการรักษาความปลอดภัย', hours: 2 },
  { title: 'การรักษาความปลอดภัยขั้นพื้นฐาน', hours: 7 },
  { title: 'การเขียนรายงาน', hours: 2 },
  { title: 'การเตรียมพร้อมกรณีเหตุฉุกเฉิน', hours: 5 },
  { title: 'การติดต่อสื่อสาร', hours: 2 },
  { title: 'หลักการใช้กำลัง', hours: 4 },
  { title: 'การปฐมพยาบาลเบื้องต้น', hours: 4 },
  { title: 'การจัดการจราจร', hours: 3 },
  { title: 'การฝีกภาคสนาม', hours: 9 }
];

export default function Education() {
  return <>
    <div className="page-wrapper">
      <Header />
      <div>
        <div className="container">
          <div className="project-details__wrapper">
            <div className="project-details__feature">
              <img src={EducationBanner} alt="banner-image" />
              <h3 className="project-details__highligt w-100" style={{ color: '#0b2f81' }}>
                หลักสูตรรักษาความปลอดภัย รีคอน การ์ด <span className='text-danger'>(40 ชั่วโมง)</span>
              </h3>
              <ul className="list-unstyled project-details__feature__list">
                {educationList.map((data, index) => (
                  <li key={index}>
                    {index + 1}. {data.title} <span className='text-danger'>{data.hours} ชั่วโมง</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </>
}