import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main';
import { Link } from 'react-router-dom';

export default function ContactUs() {
  return <>
    <Header />
    <div>
      <section className='my-5'>
        <div className="container">
          <div className="contact-one__inner">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-one__content">
                  <div className="sec-title text-@@textAlign">
                    <h4 style={{ color: '#0b2f81' }}>ติดต่อเรา</h4>
                    <h3 className="sec-title__title mt-4">
                      บริษัทรักษาความปลอดภัย<br /> รีคอนการ์ด จำกัด
                    </h3>
                  </div>
                  <p className="contact-one__text">
                    พร้อมให้คำปรึกษาและรายละเอียดเกี่ยวกับงานรักษาความปลอดภัย
                    <p className='text-danger'>เพราะความปลอดภัยของท่านคือหน้าที่ของพวกเรา</p>
                  </p>
                  <ul className="list-unstyled contact-one__info">
                    <li className="contact-one__info__item">
                      <div className="contact-one__info__icon">
                        <i className="icon-phone-call-1" />
                      </div>
                      <div className="contact-one__info__content">
                        <p className="contact-one__info__text" style={{ color: '#00cde5' }}>โทรศัพท์</p>
                        <h4 className="contact-one__info__title">
                          <Link to="tel:+6693-6434525">093-6434525</Link>
                        </h4>
                      </div>
                    </li>
                    <li className="contact-one__info__item">
                      <div className="contact-one__info__icon">
                        <i className="icon-placeholder" />
                      </div>
                      <div className="contact-one__info__content">
                        <p className="contact-one__info__text" style={{ color: '#00cde5' }}>ที่อยู่</p>{" "}
                        <h4 className="contact-one__info__title">
                          <Link to="#">39/25 หมู่ที่ 10 ต.บางเสร่ อ.สัตหีบ จ.ชลบุรี</Link>
                        </h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </>
}