import React from 'react';
import Teamdtls1 from '../../assets/images/team/team-d-1.png';
import VisionIcon from '../../assets/images/reconImages/vision/vision_icon.jpg';

function Details() {
    const socialLinks = [
        { icon: 'fab fa-facebook', url: 'https://facebook.com', label: 'Facebook' },
    ];

    const listItems = [
        'ลูกค้าคือคนสำคัญ',
        'พนักงานคือหัวใจของงาน',
        'มุ่งมั่นก้าวทันเทคโนโลยี',
        'ซื่อสัตย์ มีวินัย ใฝ่รู้ คู่คุณธรรม',
        'ก้าวสู่มาตรฐานสากล',
    ];

    return (
        <>
            <section className="my-5">
                <div className="container">
                    <div className="team-details__inner p-5">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="team-details__image p-5">
                                    <img src={VisionIcon} alt="vision_icon" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="team-details__content">
                                    <h4 style={{ color: '#0b2f81' }}>วิสัยทัศน์</h4>
                                    <div className="team-details__text">
                                        มุ่งมั่นพัฒนางานรักษาความปลอดภัยอย่างมืออาชีพ เพื่อให้บริการที่ดี <br /> มีประสิทธิภาพ และสร้างความประทับใจและไว้วางใจจากลูกค้าตลอดไป
                                    </div>
                                    <div>
                                        <h4 className='mt-3'>
                                            พันธกิจ
                                        </h4>
                                        <p>
                                            1. บริหารจัดการตามระบบคุณภาพ โดยควบคุม กำกับดูแล ให้เกิดประสิทธิภาพสูงสุดต่อองค์กร และผู้ใช้บริการ (Monitoring and assessment) <br />
                                            2. อบรมพนักงาน ให้เป็นมืออาชีพ ทางด้านงานรักษาความปลอดภัยตามเจตนารมณ์ของกฎหมายอย่างต่อเนื่อง <br />
                                            3. นำเทคโนโลยี และนวัตกรรม มาใช้เพื่อเพิ่มประสิทธิภาพในการทำงาน (Recon Security Checking system) <br />
                                            4. ตอบสนองต่อสถานการณ์ฉุกเฉินที่ไม่คาดคิด อย่างทันท่วงทีในทุกมิติ (Team Fire, Bodyguard, ครูฝึก, สายตรวจ)<br />
                                            5. สร้างมาตรฐานให้กับองค์กรเพื่อการพัฒนาศักยภาพ รปภ.และบุคคลากร เพื่อเข้าสู่มาตรฐานสากล (ISO 9001:2018) (ISO 45001:2018)<br />
                                            6. ยกระดับการให้บริการโดยให้ความสำคัญต่อระดับคุณภาพการรักษาความปลอดภัยด้วยการพัฒนาบุคลากร, การนำเทคโนโลยีมาใช้ในการพัฒนาการให้บริการ ตลอดจนสร้างความร่วมมือกับผู้มีส่วนได้ส่วนเสียและคำนึงถึงความต้องการของลูกค้าเป็นสำคัญ<br />
                                        </p>
                                    </div>
                                    <div>
                                        <h4 >
                                            ค่านิยมองค์กร
                                        </h4>
                                        <ul className="list-unstyled team-details__list">
                                            {listItems.map((item, index) => (
                                                <li key={index}>
                                                    <i className="fa fa-check-circle" />
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Details;
