import React, { useState, useEffect } from 'react';
import About1 from '../../assets/images/resources/about-1-1.jpg'
import Aboutshape1 from '../../assets/images/resources/about-1-shape-1.png'
import title from '../../assets/images/reconImages/home/title.jpg'

function About() {
    const initialCounts = {
        count: 0,
    };
    const finalCounts = {
        count: 23,
    };

    const [counts, setCounts] = useState(initialCounts);

    useEffect(() => {
        const updateCounter = () => {
            const interval = 100;
            const timers = Object.keys(finalCounts).map((key) => {
                const startCount = counts[key];
                const endCount = finalCounts[key];
                const step = Math.ceil((endCount - startCount) / (1000 / interval));

                return setInterval(() => {
                    setCounts((prevCounts) => ({
                        ...prevCounts,
                        [key]: Math.min(prevCounts[key] + step, endCount),
                    }));
                }, interval);
            });
            return () => {
                timers.forEach((timer) => clearInterval(timer));
            };
        };
        updateCounter();
    }, []);

    return (
        <div>
            <section className="about-one">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="about-one__image wow fadeInLeft" data-wow-delay="300ms">
                                <div className="about-one__image-one">
                                    <img src={title} alt="title" />
                                    <img className="about-one__image-one__shape" src={Aboutshape1} alt="hiredots" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 wow fadeInRight" data-wow-delay="300ms">
                            <div className="about-one__content">
                                <div className="sec-title text-left">
                                    <h6 className="mt-5" style={{ color: '#00cde5' }}>เกี่ยวกับบริษัทของเรา</h6>
                                    <h3 className="sec-title__title">
                                        บริษัท รักษาความปลอดภัย <br /> รีคอน การ์ด จำกัด
                                    </h3>
                                </div>
                                <p className="about-one__content__text">
                                    เป็นบริษัทรักษาความปลอดภัย พนักงานรักษาความปลอดภัย
                                    ที่มีความน่าเชื่อถือ มีทีมงานที่ผ่านการอบรบ มีมาตรฐานสากล มีความสามารถ
                                    มีความอดทน ซื่อสัตย์ เข้มแข็ง เราเป็นบริษัทรักษาความปลอดภัยที่จดทะเบียนบริษัทที่ถูกกฎหมาย มีความน่าเชื่อถือ เป็นบริษัทรักษาความปลอดภัยที่มีประสบการณ์สูง
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                    </div>
                                    <div className="col-md-6">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About